export const defaultConfig = {
    messages: {
        es: {
            benefits_none: ' ',
            benefits_welcome: 'Explora los beneficios que tu empresa tiene para ti',
            global_org_label: 'Empresa',
            index_benefits: '¡Aprovecha los beneficios exclusivos por parte de tu empresa!',
            index_community: 'VinColegas en tu empresa',
            index_welcome: 'Explora la nueva prestación educativa de tu empresa'
        },
    }
};

export const defaultLanguage = 'es';

// export const supportCoaches = '525596619030' // nuevo soporte coaches
// export const chatwoot = '5215593378430' // Nuevos usuarios
// export const walmart = '5215590354326' //asociados
// export const walmartClientes = '5215590354280' //tarjetas

export const supportCoaches = '5218185250627' // nuevo soporte coaches
export const chatwoot = '5218153507424' // Nuevos usuarios
export const walmart = '5218185250627' //asociados
export const walmartClientes = '5215590354280' //tarjetas





export const findStatusApplications = (applications = []) => {

    if(applications?.length >= 1){

        const applicationFound = applications?.find(({application_status}) => application_status === 'Registrado');  

        if(applicationFound){
            return supportCoaches;
        }else{
            return chatwoot;
        }

    }else{
        return chatwoot;
    }
}

export const useConfig = (serverConfig, keys) => {
    try {
        let target = serverConfig;
        keys.split('.').forEach(key => {
            target = target[key];
        });
        if (target) {
            return target;
        } else {
            throw new Error('no target in server config');
        }
        
    } catch (err) {

        let target = defaultConfig;
        keys.split('.').forEach(key => {
            target = target[key];
        });
        if (target) {
            return target;
        } else {
            throw new Error(`no target ${keys} in default config`);
        }

    }
};

export const replaceNameEducation = (nameProgram,config) =>{
    if(config){
      if(nameProgram?.toLowerCase() === 'preparatoria' && config.programGrade?.preparatoria){
        return nameProgram = config.programGrade['preparatoria']
      }
      if(nameProgram?.toLowerCase() === 'profesional' && config.programGrade?.licenciatura){
        return nameProgram = config.programGrade['licenciatura']
      }
    }
    return nameProgram
  }
